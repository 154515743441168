<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>{{ pageTitle }}</h4>
      <div>
        <b-button
          v-if="componentMode === 'create'"
          type="submit"
          form="routePointForm"
          class="mr-2"
          variant="outline-primary"
        >
          Создать
        </b-button>
        <b-button
          v-if="componentMode === 'edit'"
          type="submit"
          form="routePointForm"
          class="mr-2"
          variant="outline-primary"
        >
          Сохранить
        </b-button>
        <b-button variant="outline-secondary" @click="cancel">Отмена</b-button>
      </div>
    </div>
    <b-row class="mt-2">
      <b-col cols="6">
        <form @submit.prevent="onSubmit" id="routePointForm">
          <b-form-group label="Место" label-class="pb-0">
            <b-form-select v-model="model.place_id">
              <b-form-select-option v-for="place in places" :key="place.id" :value="place.id">
                {{ place.title }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </form>

        <!-- <b-form-group label="Описание" label-class="pb-0">
          <b-form-textarea v-model="model.description" rows="5" />
        </b-form-group>

        <div v-if="componentMode === 'edit'">
          <h4 class="mt-3">Аудио</h4>
          <DropBox
            v-if="!model.audio_file"
            accepted-only
            accept="audio/mpeg"
            @input="onAudioInput"
          />
          <AudioPlayer v-else :file="model.audio_file" @delete="onAudioDelete" />
        </div> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { Audio, Place, RoutePoints, Routes } from '@/request';

import AudioPlayer from '@/components/AudioPlayer.vue';
import DropBox from '@/components/DropBox.vue';

export default {
  name: 'RoutePoint',
  components: { DropBox, AudioPlayer },
  computed: {
    componentMode() {
      return this.$route?.meta?.mode || 'create';
    },
    pageTitle() {
      return this.componentMode === 'edit'
        ? 'Редактировать точку маршрута'
        : 'Создать точку маршрута';
    },
  },
  data() {
    return {
      model: {
        description: '',
        audio_file: undefined,
        place_id: undefined,
        route_id: undefined,
        order_number: undefined,
      },
      places: [],
      currentRoute: undefined,
    };
  },
  beforeMount() {
    this.getPlaces();
    this.getRoute();
    if (this.componentMode === 'edit') {
      this.getRoutePoint();
    } else {
      this.model.route_id = this.$route.params.id;
    }
  },
  methods: {
    async getPlaces() {
      try {
        const { data } = await Place.getPlaces({ per_page: 999 });
        this.places = data;
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async getRoute() {
      try {
        this.currentRoute = await Routes.getRoute(this.$route.params.id);
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async getRoutePoint() {
      try {
        Object.assign(this.model, await RoutePoints.getRoutePoint(this.$route.params.pointId));
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async createRoutePoint() {
      try {
        this.model.order_number = this.getNextOrderNumber();
        const newPoint = await RoutePoints.createRoutePoint(this.model);
        this.$bvToast.toast('Новая точка маршрута создана', { variant: 'success' });
        await this.$router.push({
          name: 'EditRoutePoint',
          params: { id: this.$route.params.id, pointId: newPoint.id },
        });
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async updateRoutePoint() {
      try {
        await RoutePoints.updateRoutePoint(this.$route.params.pointId, this.model);
        this.$bvToast.toast('Точка маршрута обновлена', { variant: 'success' });
        await this.$router.push({ name: 'EditRoute', params: { id: this.$route.params.id } });
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    onSubmit() {
      switch (this.componentMode) {
        case 'create':
          this.createRoutePoint();
          break;
        case 'edit': {
          this.updateRoutePoint();
          break;
        }
        default:
          return;
      }
    },
    cancel() {
      this.$router.push({ name: 'EditRoute', params: { id: this.$route.params.id } });
    },
    async onAudioInput(files) {
      if (!files.length) {
        return;
      }

      const formData = new FormData();
      formData.append('file', files[0], files[0].name);

      try {
        await Audio.uploadRoutePointAudio(this.$route.params.pointId, formData);
        await this.getRoutePoint();
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async onAudioDelete() {
      try {
        await Audio.deleteRoutePointAudio(this.$route.params.pointId);
        await this.getRoutePoint();
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    getNextOrderNumber() {
      if (!this.currentRoute) {
        throw new Error('Не возможно определить порядок точек');
      }

      if (!this.currentRoute.points?.length) {
        return 1000;
      }

      let maxOrderNumber = 0;
      for (const point of this.currentRoute.points) {
        if (point.order_number > maxOrderNumber) {
          maxOrderNumber = point.order_number;
        }
      }

      return maxOrderNumber + 1000;
    },
  },
};
</script>

<style lang="scss" scoped></style>
